.red .MuiLinearProgress-barColorPrimary {
    background-color: red;
  }
  
 .orange .MuiLinearProgress-bar {
    background-color: #F39C12;
  }
  
 .green .MuiLinearProgress-bar {
    background-color: #27AE60;
  }

  .gray .MuiLinearProgress-bar{
    background-color: #bdbdbd;

  }
  
  .greenBackground {
    background-color: #D4EFDF
  }

  .grayBackgroundColor{
    background-color: #bdbdbd;
  }

  .redBackground {
    background-color:#F5B7B1
  }

  .orangeBackground {
    background-color: #FDEBD0
  }

  .primaryBackGround{
    background-color: primary;
  }

  .MuiStepConnector-lineHorizontal{
    border-top-width:0px ;
  }
  .MuiStepper-alternativeLabel{
    background-color: inherit;
  }

  .timeline-connector{ 
    top: 50%;
    transform: translateY(-50%);
    left: calc(-30% + 2px);
    right: calc(70% + 2px);
    position: absolute;
    background-color: #bdbdbd;
    min-height: 3px;
}

.deviation-connector{ 
  top: 50%;
  transform: translateY(-50%);
  left: calc(-30% + 2px);
  right: calc(90% + 10px);
  position: absolute;
  background-color: #bdbdbd;
  min-height: 3px;
}

.timeline-dot {
  height: 10px;
    width: 10px;
    border-radius: 50px;
    z-index: 100;
}

.txt-sm-timeline {
  font-size: smaller;
}