.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-jsb {
  display: flex;
  justify-content: space-between;
}

.d-jsb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-jsb-cen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-jsb-r {
  display: flex;
  justify-content: right;
  align-items: center;
}

.jsb-l {
  justify-content: left;
  align-items: center;
}

.d-jsb-cen-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.d-f-1-col {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.d-l {
  display: flex;
  align-items: flex-start;
}

.al-flx-s {
  align-items: flex-start;
}

.al-c {
  align-items: center;
}

.al-l {
  align-items: left;
}

.pointer {
  cursor: pointer;
}

.progress-primary {
  background-color: #5a83bb;
  border-radius: 5px;
  height: 5px;
}

.progress-base {
  background-color: rgb(192, 207, 229);
  border-radius: 5px;
  height: 5px;
}

.txt-elps {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-wrap {
  word-break: break-word;
}

.txt-center {
  text-align: center;
}

.txt-end {
  text-align: end;
}

.txt-bold {
  font-weight: bold;
}

.txt-primary {
  color: #5a83bb;
}

.txt-white {
  color: #ffffff;
}

.txt-black {
  color: black;
}

.txt-grey {
  color: #7c7c7c;
}

.txt-green {
  color: rgb(39, 174, 96);
}

.txt-red {
  color: #e74c3c;
}

.txt-sm {
  font-size: x-small;
}

.txt-md {
  font-size: small;
}

.txt-md {
  font-size: 0.9rem;
}

.border-r-1rem {
  border-radius: 1rem;
}

.border-r-20 {
  border-radius: 20px;
}

.btn-error {
  color: red;
  border: 1px solid red;
}

.btn-sdw {
  box-shadow: 1px 1px 5px lightgrey;
  cursor: pointer;
  border-radius: 10px;
}

.chip {
  height: 20px;
  font-size: 0.6rem;
}

.chip-clear {
  border-radius: 15px;
  font-size: 0.6rem;
}

.chip-success {
  border: 1px solid green;
  color: green;
  background-color: lightgreen;
}

.chip-error {
  border: 1px solid red;
  color: red;
  background-color: white;
}

.chip-primary {
  border: 1px solid #5a83bb;
  color: #5a83bb;
  background-color: white;
}

.chip-error-contained {
  border: 1px solid red;
  color: white;
  background-color: red;
}

.card-sdw {
  box-shadow: 1px 1px 5px lightgrey;
  border-radius: 5px;
}

.sdw-none {
  box-shadow: none;
}

.table-border-light {
  border: 1px solid #efeff0;
}

.pd-0 {
  padding: 0;
}

.pd-5 {
  padding: 5px;
}

.pd-10 {
  padding: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-20 {
  padding: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.p-lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pd-phase-box {
  padding: 5px 10px;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10-neg {
  margin-top: -10px;
}

.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.ht-100 {
  height: 100%;
}

.min-ht-35 {
  min-height: 35px;
}

.min-ht-50 {
  min-height: 50px;
}

.max-ht-620 {
  max-height: 620px;
}

.max-ht-700 {
  max-height: 700px;
}
.wd-10 {
  width: 10%;
}

.wd-20 {
  width: 20%;
}

.wd-30 {
  width: 30%;
}

.wd-31 {
  width: 31%;
}

.wd-48 {
  width: 48%;
}

.wd-50 {
  width: 50%;
}

.wd-60 {
  width: 60%;
}

.wd-55 {
  width: 55%;
}

.wd-70 {
  width: 70%;
}

.wd-100 {
  width: 100%;
}

.mwd-200 {
  min-width: 200px;
}

.mwd-800 {
  min-width: 800px;
}

.scroll-x {
  overflow-x: auto;
  flex-wrap: nowrap;
}

.scroll-y {
  overflow-y: auto;
}

.f-wrap {
  flex-wrap: wrap;
}

.footer-btn {
  position: fixed;
  bottom: 0;
  color: gray;
}

.txt-capital {
  text-transform: capitalize;
}

.txt-capitalize {
  display: inline-block;
  text-transform: lowercase;
}

.txt-capitalize:first-letter {
  text-transform: uppercase;
}

.avatar .MuiAvatar-colorDefault {
  background-color: #5a83bb;
  height: 30px;
  width: 30px;
  font-size: 0.7rem;
  font-weight: bold;
}

div.phase-list::-webkit-scrollbar {
  width: 1px;
}

div.phase-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.text-error {
  margin: 5px 0px;
  color: #f44336;
  font-size: 11px;
}
.text-error-custom {
  margin-top: 5px;
  margin-bottom: 0px;
  color: #f44336;
  font-size: 11px;
}

.nav-color {
  background-color: #f7f9fc;
}

.new-notification {
  background-color: #5a83bb;
  border-radius: 20px;
  height: 8px;
  width: 8px;
}

#notification-link {
  color: #5a83bb;
  text-decoration: underline;
}

.no-data-available {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.no-data-available-milestone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  height: 120px;
  width: 100%;
}

.custom-container {
  box-shadow: 0 0 14px 0 rgba(53, 64, 82, 0.2);
  border-radius: 6px;
  background-color: white;
}

#n-d-p {
  margin-top: 2px;
  margin-bottom: 2px;
}
#n-t-p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.sortableHelper {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  justify-content: space-between;
}

.snapshot-text {
  margin: 1px;
  font-size: 10px;
}

.snapshot-user-text {
  margin: 1px;
  font-size: 10px;
}

.div-user-list {
  width: 93%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  height: 130px;
  overflow-x: auto;
  margin-left: 20px;
}

.snapshot-empty-cell {
  background-color: #d6d2d2;
}

.snapshot-user-border {
  border-bottom: 0.5px solid grey;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.select-snapshot-phase {
  border: 0px;
  font-size: 14px;
  color: #6281b6;
  width: 120px;
  overflow: hidden;
  height: 30px;
  text-overflow: ellipsis;
}

.snapshot-header-border {
  border-right: 1px solid grey;
}

.snapshot-header-border-initial {
  border-right: 1px solid grey;
}

.snapshot-body-border-initial {
  border-right: 1px solid grey;
}

.snapshot-body-border-right {
  border-right: 1px solid grey;
}

.snapshot-link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #5a83bb;
}

.snapshot-text:hover {
  cursor: pointer;
  text-decoration: underline;
  fill: #5a83bb;
}

.prework-tab {
  padding: 0px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  margin-bottom: -5px !important;
}

.d-jsb-cen-imp {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.side-nav-text {
  display: inline-block;
  text-wrap: wrap;
  width: 200px;
}

.MuiTab-root {
  min-width: 110px !important;
}

#root {
  overflow-y: auto;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.workflow-full-screen-buttons-top-left {
  position: fixed;
  z-index: 1251;
  color: black;
  top: 0;
  left: 0;
  width: auto;
  height: 50px;
  padding: 10px;
}

.workflow-full-screen-buttons-top-right {
  position: fixed;
  z-index: 1251;
  color: black;
  top: 0;
  right: 0;
  width: auto;
  height: 50px;
  padding: 10px;
}

.workflow-full-screen-buttons-bottom {
  position: absolute;
  z-index: 1251;
  color: black;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
}

.settings-stepper {
  height: 20px;
  width: 20px;
  border-radius: 15px;
  padding-top: 1.5px;
  text-align: center;
  background-color: grey;
  font-size: 12px;
  color: white;
  cursor: pointer;
}

.milestone-star-small {
  padding-top: 5px;
  margin-bottom: -4px;
}

.milestone-star-medium {
  padding-top: 5px;
  margin-bottom: -1px;
}

.milestone-diamond-small {
  padding-top: 3px;
  margin-left: 2px;
  margin-right: 2px;
}

.milestone-diamond-medium {
  padding-top: 1px;
  margin-left: 5px;
  margin-right: 5px;
}

#tooltip-status {
  background: white;
  font-size: 14px;
  border-radius: 2px;
  padding: 12px;
  min-width: 100px;
  min-height: 10px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

#tooltip-name {
  background: white;
  font-size: 12px;
  border-radius: 2px;
  padding: 12px;
  min-width: 10px;
  min-height: 10px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.bottom-sticky {
  position: sticky;
  bottom: 0;
}

.card-border {
  border: #7c7c7c solid 1px;
  border-radius: 10px;
}

.table-box {
  height: "70vh";
  max-height: "70vh";
}

.black__icon path {
  fill: #000000;
}

.yellow__icon path {
  fill: #ffb950;
}

.green__icon path {
  fill: #5ba300;
}

.red__icon path {
  fill: #d32f2f;
}

.blue__icon path {
  fill: #0953b9;
}

.grey__icon path {
  fill: #676869;
}

.grey_bg {
  background-color: #676869;
}

.light-grey__icon path {
  fill: #e0e0e0;
}

.primary__icon path {
  fill: #5a83bb;
}

.white__icon path {
  fill: #ffffff;
}

.icons_xsmall {
  height: 12px;
  width: 12px;
}

.icons_msmall {
  height: 15px;
  width: 15px;
}

.icons_small {
  height: 18px;
  width: 18px;
}

.icons_reg {
  height: 20px;
  width: 20px;
}

.icons_medium {
  height: 34px;
  width: 34px;
}

.icon-large {
  height: 32px;
  width: 32px;
}

.icon-xl-large {
  height: 64px;
  width: 64px;
}

.icon_space {
  margin-left: 6px;
  margin-right: 6px;
}

.icon_space_left {
  margin-left: 6px;
}

.icon_space_right {
  margin-right: 6px;
}

.icons_space_small {
  margin-left: 4px;
  margin-right: 4px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.fw-wt-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.fs-large {
  font-size: large;
}

.fs-1rem {
  font-size: 1rem;
}

.button-cancel {
  border-radius: 20px;
  color: #676869;
  border-color: #676869;
}

.bg-primary {
  background-color: #5a83bb;
}

.work-breakdown-line {
  height: 3px;
  width: 20px;
  background-color: #676869;
  margin-bottom: 25px;
}

.round-icon-button {
  background-color: #e5f1fd;
  padding: 0.75rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.round-icon-button-grey {
  background-color: #49454f14;
  padding: 0.5rem;
}

.auto-gen-div {
  height: 50px;
  background-color: #d9d9d9;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid #9b9b9b;
  color: gray;
}

.no-data-img {
  width: 500px;
  height: 500px;
  object-fit: contain;
}

.no-data-text {
  font-size: 1.3rem;
  font-weight: 600;
  color: #676869;
}

.chip-status-blue {
  border: 1px solid #5a83bb;
  color: white;
  background-color: #5a83bb;
}

.chip-status-red {
  border: 1px solid #d32f2f;
  color: white;
  background-color: #d32f2f;
}
.chip-status-green {
  border: 1px solid #5ba300;
  color: white;
  background-color: #5ba300;
}
.chip-status {
  height: 25px;
  font-size: 0.6rem;
}
.round-chip {
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
}
.pos-rel {
  position: relative;
}
.pos-abs-tr-5 {
  position: absolute;
  top: 5px;
  right: 5px;
}
.custom-card-shadow {
  box-shadow: 2px 2px 3px 0 #0953b926;
}
.b-sdw-none {
  box-shadow: none;
}
.check-box-sm {
  height: 2rem;
  width: 2rem;
}
.check-box-xsm {
  height: 1rem;
  width: 1rem;
}

.pos-abs-tr {
  position: absolute;
  top: 0%;
  right: -10%;
}

.reg-icon-button {
  background-color: #e5f1fd;
  padding: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.reg-icon-button-teal {
  background-color: #d3e7e9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.teal-bg {
  background-color: #d3e7e9;
}

.reg-icon-button-white {
  background-color: white;
  padding: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.reg-icon-btn-flat {
  background-color: #e5f1fd;
  padding: 0.5rem;
}

.chip-ontime-completed-deliverable {
  background-color: #d5edb6;
  color: #456a16;
}

.chip-ontime-pending-deliverable {
  background-color: #e1edff;
  color: #165cbd;
}

.chip-late-completed-deliverable {
  background-color: #ffe5be;
  color: #81591c;
}

.chip-late-pending-deliverable {
  background-color: #fdd8d8;
  color: #b51616;
}

.res-avatar-deliverable {
  background-color: #0953b9;
  height: 1.4rem;
  width: 1.4rem;
  font-size: 8px;
  font-weight: 500;
}
