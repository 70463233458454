.milestone-star {
  width: 20px;
  margin-bottom: 5px;
}

.grey_icon path {
  fill: #bdbdbd;
}

.darkgrey_icon path {
  fill: #5e5e5e;
}

.red_icon path {
  fill: #d32f2f;
}

.primary_icon path {
  fill: #5a83bb;
}

.green_icon path {
  fill: #27ae60;
}

.orange_icon path {
  fill: #f39c12;
}

.avatar-deliverable .MuiAvatar-colorDefault {
  background-color: #0953b9;
  height: 30px;
  width: 30px;
  font-size: 0.7rem;
  font-weight: bold;
}

.chip-success-deliverable {
  background-color: #d5edb6;
  color: #456a16;
}

.chip-error-deliverable {
  background-color: #fdd8d8;
  color: #b51616;
}

.chip-primary-deliverable {
  background-color: #e1edff;
  color: #165cbd;
}

.chip-delay-deliverable {
  background-color: #ffe5be;
  color: #81591c;
}

.deliverable-chip {
  border: none;
  height: 30px;
  cursor: pointer;
}

.success-checkcircle path {
  fill: #5ba300;
}

.error-checkcircle path {
  fill: #d32f2f;
}

.delay-checkcircle path {
  fill: #ffb950;
}

.primary-checkcircle path {
  fill: #0954b9;
}
